.IssueTrackerFilters {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
}

.IssueTrackerFilters > * {
  flex-shrink: 0;
}

.IssueTrackerFilters :global(.bx--list-box__menu) {
  min-width: 250px;
}

.IssueTrackerFilters :global(.bx--list-box__menu-item__option) {
  padding-right: 0;
}

.wideDropdown :global(.bx--list-box__menu) {
  width: 470px;
}

.dropdownItem {
  display: flex;
  justify-content: space-between;
}

.dropdownItem .dropdownName {
  text-overflow: ellipsis;
  flex-grow: 0;
  overflow: hidden;
}

.categoryItem {
  margin-left: 1rem;
}

.categoryItem .dropdownName::before {
  content: "\00BB";
  margin-right: 0.5rem;
}