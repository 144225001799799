.key {
  color: #555;
  margin-bottom: 1rem;
}

.key svg {
  vertical-align: -2px;
  margin-right: 2px;
}

.ibmEmployee {
  background-color: #fbfbfb;
  border: 1px solid #f3f3f3;
  margin-bottom: 2rem;
  padding: 1rem;
}

.ibmEmployee .info {
  margin-bottom: 1rem;
}

.ibmEmployee .secondaryInfo {
  font-size: 0.9rem;
  margin-bottom: 2rem;
}

.ibmEmployee .formRow:last-child {
  margin-bottom: 0;
}

.hiddenIcon {
  vertical-align: -3px !important;
  margin-left: 5px !important;
}

.formRow {
  margin-bottom: 2rem;
}

.formRow :global(.bx--list-box__label) {
  text-align: left
}

.formRowCheckbox {
  margin-bottom: 0.5rem;
}

.formRowCheckbox p{
  font-size: inherit;
}

.visibilityLimitationInfo {
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.formError {
  font-size: 0.75rem;
  color: #da1e28;
  margin-top: 1rem;
}

.buttonSet {
  gap: 1rem;
}

.tagsContainer {
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.2rem;
}

.richTextEditorWrapper {
  margin-bottom: 1rem;
}

.richTextEditor.invalid {
  outline: 2px solid #da1e28;
  outline-offset: -2px;
}

.richTextEditorWrapper .formError {
  margin-top: 0.25rem;
  line-height: 1.33333;
}


.formSection {
  margin-bottom: 4rem;
}

.formSection h3 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.label {
  display: block;
}

.shortField {
  max-width: 600px;
}

.linkField {
  background-color: #f5f5f5;
  padding: 1rem;
}

.linkField input {
  border: none;
  padding: 0 1rem;
  width: 100%;
}

.linkField label {
  display: block;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

.inline {
  display: flex;
}