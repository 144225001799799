.toastContainer {
  position: fixed;
  top: 60px;
  right: 10px;
  z-index: 99999;
}

.toastEnter {
  margin-left: 100%;
  transition: margin-left 200ms ease-out;
}

.toastEnterActive {
  margin-left: 0;
}

.toastExit {
  margin-left: 0;
  transition: margin-left 200ms ease-in;
}

.toastExitActive {
  margin-left: 100%;
}
