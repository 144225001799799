
.IssueTrackerHome section {
    margin-bottom: 2rem;
}

.IssueTrackerHome h4 {
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.IssueTrackerPersona {
    font-style: italic;
}

 