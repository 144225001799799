.editorUI {
  background-color: #f4f4f4;
  border-bottom: 1px solid #c6c6c6;
}

.editorUI .active {
  background-color: #c6c6c6;
}

.groupButton{
  color: #000;
  width: 100%
}

.groupButton:hover {
  color: #000;
}

.textArea {
  font-size: 0.875rem;
}

.textArea, .textArea:active {
  padding: 0;
  outline: none;
}

.textArea :global(.ck-content), .textArea :global(.ck-content):focus, .textArea :global(.ck-content):active {
  padding: 1rem !important;
  outline: none;
  box-shadow: none !important;
}

.textArea i {
  font-style: italic;
}

.textArea p {
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
  font-size: inherit;
}

.textArea ul, .textArea ol {
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
  margin-left: 1.5rem;
  font-size: inherit;
}
