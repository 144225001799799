.itemCard {
  scroll-margin-top: 4rem;
}

.itemCard table {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.itemCard p {
  margin-bottom: 0.5rem;
  font-size: inherit;
  line-height: inherit;
  line-height: 1.4;
  word-break: break-word;
}

.itemCard h5 {
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.itemCard b { font-weight: 500; }
.itemCard i { font-style: italic; }


.header {
  background-color: rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  padding: 0.1rem 0.5rem;
  margin-bottom: 1rem;
}

.header h4 {
  flex-grow: 1;
  font-weight: 500;
}

.header h4 a {
  text-decoration: none;
  color: #333;
}


.header h4 .visibilityIcon {
  margin-right: 0.2rem;
  vertical-align: -2px;
}

.actionsMenu { width: 15rem; }
.actionsMenu :global(.bx--overflow-menu-options__btn) {
  max-width: none;
}

.itemCard .cols {
  display: flex;
  gap: 2rem;
  margin: 0.5rem;
  font-size: 0.9rem;
  line-height: 1.4;
}

.itemCard .cols > .col {
  flex: 1;
}

.itemLink {
  font-size: inherit;
  text-decoration: none;
}

.itemLink svg {
  vertical-align: -2px;
}

.meta {
  margin-bottom: 0.2rem;
}

.meta th {
  font-weight: 500;
  text-align: left;
  padding-right: 1rem;
}

.meta th, .meta td {
  vertical-align: middle;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  border-bottom: 1px solid #e5e5e5;
}

.meta tr:last-child th, .meta tr:last-child td {
  border-bottom: none;
}

.itemCard .meta {
  width: 100%;
}

.itemCard .meta th {
  width: 180px;
}

.itemCard :global(.bx--list-box__wrapper) {
  min-width: 100%;
  width: 100%;
}

.itemCard :global(.bx--list-box__menu-item__option) {
  padding-right: 0;
}

.classificationThemesDropdownContainer :global(.bx--list-box__menu) {
  min-width: 220px;
}

.managedTagsDropdownContainer :global(.bx--list-box__menu) {
  min-width: 350px;
}

.managedTags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.metaExtra strong {
  font-weight: 500;
}

.actionButtons {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
}

.addClusterIdeaForm {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;

}

.addClusterIdeaForm :global(.bx--text-input__label-helper-wrapper) {
  flex: 8 1;
  margin-right: 0;
}


.clusterIdInput {
  width: 300px;
}

.redacted {
  font-style: italic;
}
