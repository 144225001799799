.companyGuess {
  font-size: 0.875rem;
  background-color: #fbfbfb;
  border: 1px solid #f3f3f3;
  margin-bottom: 1rem;
  padding: 1rem;
}

.companyGuess .options {
  margin-top: 0.5rem;
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
}
