.filterOptions {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.multiselectOptions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.keyButtons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.ideaRow {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.ideaRow > div {
  flex-shrink: 1;
  flex-grow: 1;
  min-width: 0;
}

.ideaRow .ideaSelectCheckbox {
  flex-basis: 0;
  flex-grow: 0;
  min-width: auto;
}

.errorMessage {
  max-width: none;
}

.listTotal {
  float: right;
  padding-right: 5px;
  padding-bottom: 5px;
}
