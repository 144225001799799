.devInfo, .userInfo {
    width: 100%;
    padding: 1rem;
    color: #256a0c;
}

.userInfo {
    color: #db1313 !important;
}

.devInfo h5, .userInfo h5 {
    font-size: 12px;
}