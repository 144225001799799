.header {
  margin-bottom: 1rem;
}

.groupTagsSection {
  margin-bottom: 2rem;
}

.tagLink{
  text-decoration: none;
}

.tagsTable td,
.tagsTable th {
  padding-right: 1rem;
  vertical-align: middle;
}

.tagsTable th {
  font-weight: bold;
  text-align: left;
  padding-bottom: 0.5rem;
}


.colTagName {
  min-width: 300px;
}

.editTagNameInput {
  height: 32px;
}

.editRow {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.tagsTable th:not(:first-child),
.tagsTable td:not(:first-child) {
  text-align: center;
}
