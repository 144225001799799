.EditIdeaDialog :global(.bx--modal-content) {
  overflow-y: auto;
}

.info {
  padding: 0.5rem;
  border: 1px solid rgba(0,0,0,0.1);
  margin-bottom: 1rem;
}

.EditIdeaDialog p {
  padding-right: 0;
  margin-bottom: 1rem;
}

.formRow {
  margin-bottom: 2rem;
}

.shortField {
  max-width: 600px;
}

.tagsContainer {
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.2rem;
}

.formError {
  font-size: 0.75rem;
  color: #da1e28;
  margin-top: 0.25rem;
  line-height: 1.33333;
}

.EditIdeaDialog .footer button {
  max-width: 250px !important;
  padding-bottom: 1rem !important;
}

.submitContainer {
  width: 250px;
  max-width: none;
}

.submitLoading {
  height: 64px;
  margin-left: 1rem;
}

.richTextEditor.invalid {
  outline: 2px solid #da1e28;
  outline-offset: -2px;
}

.richTextEditor :global(.ck-content) {
  min-height: 6rem;
}

.redacted {
  font-style: italic;
}
