.adminResponse {
  padding: 0.5rem;
  background-color: #e8f2d9;
  border: 2px solid #7bba34;
  border-radius: 10px;
}
.responseHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.responseBody pre {
  white-space: normal;
  line-height: normal;
  width: 100%;
  margin-top: 1rem;
  background: #c7c7c74d;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  padding: 0.5em;
}

.responseBody ol {
  list-style-type: decimal;
}
.responseBody ul {
  list-style-type: disc;
}

.responseBody ul, .responseBody ol {
  margin-bottom: 1rem;
}

.responseBody li {
  margin-bottom: 0.25rem;
}
.responseBody table,
.responseBody td,
.responseBody th {
  border: 1px solid;
  padding: 0.5rem;
}
