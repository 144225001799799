.requestLogTable th {
  font-weight: bold;
  text-align: left;
  padding-bottom: 0.5rem;
}

.requestLogTable td,
.requestLogTable th {
  padding-right: 1rem;
  vertical-align: middle;
}

.requestLogTable td:first-child {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.requestLogTable td {
    font-size: 0.95rem;
    color: #646464;
}

.note {
    font-size: 0.85rem;
    color: #888;
    margin-bottom: 1rem;
}
