.header {
  margin-bottom: 1rem;
}

.groupMembersSection {
  margin-bottom: 2rem;
}

.note {
  font-size: 0.95rem;
  color: #888;
  margin-bottom: 1rem;
}

.groupMemberRequestsSection {
  margin-top: 3rem;
}