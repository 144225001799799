.settingsTable th {
    font-weight: bold;
    text-align: center;
    padding: 0.5rem;
  }

  .settingsTable td {
    vertical-align: middle;
    padding: 0.5rem 1rem;
  }
  
.notificationCheckbox label {
  margin: 0 auto;
}

.notificationCheckbox label .bx--checkbox-label-text {
  padding-left: 0;
}
