.openDropdownBtn[aria-expanded=true] {
  background-color: white;
}

.dropdown {
  font-size: 0.875rem;
  color: #525252;
  cursor: default;
  max-height: 200px;
  overflow-y: hidden;
}

.dropdown button {
  background-color: transparent;
  border: none;
  padding: 0.4375rem 1rem;
  font: inherit;
}

.dropdown button:enabled {
  cursor: pointer;
}

.yearBar {
  display: flex;
  background-color: #e5e5e5;
}

.yearBar button:enabled:hover {
  background-color: rgb(202, 202, 202);
}

.year {
  flex-grow: 1;
  padding: 0.4375rem;
  text-align: center;
}

.months {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: stretch;
  margin-block: 0.125rem;
}

.months button {
  padding: 0.5rem;
  text-align: center;
  position: relative;
  isolation: isolate;
}

.months button:enabled:hover {
  background-color: #e5e5e5;
}

.isSelecting .months button:enabled:hover {
  background-color: transparent;
}

.startDate, .endDate {
  color: white;
}

.startDate::after, .endDate::after {
  content: '';
  position: absolute;
  inset: 0.125rem 0.25rem;
  background-color: #0f62fe !important;
  border-radius: 100vw;
  z-index: -1;
}

.startDate::before, .endDate::before, .middleDate::before {
  content: '';
  position: absolute;
  background-color: #d0e2ff;
  inset: 0.125rem 0rem;
  z-index: -1;
}

.startDate::before { left: 50%; }
.endDate::before { right: 50%; }
