.IssueTrackerSummaryFilterBar {
  margin-bottom: 1rem;
  display: flex;
  gap: 0.6rem;
  flex-wrap: wrap;
}

.wideDropdown :global(.bx--list-box__menu-item__option) {
  padding-right: 0;
}

.IssueTrackerSummaryFilterBar :global(.bx--list-box__menu) {
  min-width: 250px;
}

.wideDropdown :global(.bx--list-box__menu) {
  width: 470px;
}
