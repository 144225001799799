@import '~carbon-components/css/carbon-components.min.css';
@import '~@carbon/ibmdotcom-styles';

html, body, #root {
  height: 100%;
}

body {
  /* Force there to always be a scrollbar to prevent horizontal jumping */
  overflow-y: scroll;
}

body {
  min-width: 800px;
}

h2 {
  margin-bottom: 1rem;
}
