.AddIdeaToClusterDialog :global(.bx--modal-container) h3 {
  margin-bottom: 1rem;
}

.AddIdeaToClusterDialog :global(.bx--modal-container) button {
  max-width: none;
  padding-bottom: 1rem;
}

.submitContainer {
  flex: 0 1 50%;
  padding-left: 1rem;
}
