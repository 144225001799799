.membersTable th {
  font-weight: bold;
  padding-bottom: 0.5rem;
}

.membersTable td,
.membersTable th {
  padding-right: 1rem;
  vertical-align: middle;
  text-align: center;
}

.embmersTable td:first-child,
.membersTable th:first-child {
  text-align: left;
}

.membersTable td:first-child {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
