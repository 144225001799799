.SearchResults h4 {
  margin-bottom: 0.5rem;
}

.productList {
  margin-bottom: 2rem;
  line-height: 1.3;
  column-width: 350px;
  column-gap: 1rem;
}

.productList li {
  display: block;
  border: 1px solid #E5E5E5;
  background-color: #EEE;
  margin-bottom: 1rem;
  break-inside: avoid;
}

.productList a {
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  display: flex;
  height: 100%;
  color: #131313;
  text-decoration: none;
  align-items: center;
}

.productList .productCol {
  flex-grow: 1;
}

.productList a:hover,
.productList a:focus {
  background-color: #DDD;
}

.productList .productName {
  display: block;
}

.productList .productParentName {
  display: block;
  font-size: 0.8rem;
  color: #555;
}

.productCategories {
  font-size: 0.9rem;
  border-top: 1px solid #E5E5E5;
  background-color: #FAFAFA;
  position: relative;
}

.productCategories::before {
  background-color: #CCC;
  width: 1px;
  height: 100%;
  left: 1rem;
  position: absolute;
  content: '';
}

.productCategories a {
  padding-left: 2rem;
}

.productCategories a:hover,
.productCategories a:focus {
  background-color: #EEE;
}

.productCategories a::before {
  content: '';
  width: 8px;
  height: 1px;
  background-color: #CCC;
  margin-left: -1rem;
  position: absolute;
}