.sortDropdownContainer {
  margin-left: 1rem;
}

.sortDropdown {
  width: 200px;
}

.sortDropdownContainer .sortDropdown :global(.bx--list-box__menu) {
  min-width: 0;
}
