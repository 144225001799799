.AdvancedSearchDialog :global(.bx--modal-container) {
  max-height: 84%;
}

.formInput {
  margin-bottom: 1rem;
}

.privilegedContent {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-right: 0 !important;
}

.privilegedContent svg {
  vertical-align: -2px;
}
