.votersBtns {
    border: none;
    background: transparent;
    color: inherit;
  }
  
.votersBtns:hover:enabled, .votersBtns:focus-visible:enabled {
    background-color: #e5e5e5;
    color: #161616;
    cursor: pointer;
}
  
.votersBtns.isVoted svg {
    fill: #0f62fe;
}

.voteDetails {
    display: flex;
    align-items: center;
    column-gap: 5rem;
    padding: 1rem;
}

.totalVotes, .ibmVotes, .nonIBMVotes, .initialVotes {
    text-align: center;
}

.activeBtn {
    background-color: #e5e5e5;
    color: #161616;
}

.activeBtn h5 {
    font-weight: bold;
}
