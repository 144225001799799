.groupsTable th {
  font-weight: bold;
  text-align: left;
  padding-bottom: 0.5rem;
}

.groupsTable th {
  padding-right: 1rem;
}

.groupsTable td {
  vertical-align: middle;
  padding-right: 1rem;
}

.leaveGroupBtn {
  vertical-align: middle;
  min-height: 32px !important;
  min-width: 32px !important;
}

.ideaCount {
  text-align: center;
}