.colourPicker {
  position: relative;
}

.colourPickerBtn {
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
  border-radius: 100px;
}

.colourPickerBtn.selected {
  border: 2px solid rgba(0,0,0,0.1);
}

.dropdown {
  position: absolute;
  top: -0.5rem;
  left: calc(100% + 0.5rem);
  background-color: white;
  box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
  display: flex;
  z-index: 999;
  gap: 0.5rem;
  padding: 0.5rem;
}
