.IssueTrackerSummaryFilteringBy {
  display: flex;
  align-items: stretch;
  gap: 0.2rem;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.filtersLabel {
  font-weight: 500;
  border-right: 1px solid #999;
  margin-right: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 2px;
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.filterType {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
}

.filterTypeLabel {
  /* font-size: 14px; */
  flex-shrink: 0;
}

.filterTypeTags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
