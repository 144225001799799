.voteBtn, .voteInfo{
  border: none;
  background: transparent;
  color: inherit;
}
.voteBtn:hover:enabled, .voteBtn:focus-visible:enabled {
  background-color: #e5e5e5;
  color: #161616;
  cursor: pointer;
}
.voteInfo{
  color: #161616;
  cursor: pointer;
  padding-inline: 0px;
  padding-block-end: 0px;
  padding-block-start: 4px;
}
.voteInfo svg {
  padding: 0.15rem;
}

.voteBtn.isVoted svg {
  fill: #0f62fe;
}

.voters button{
  font: inherit !important;
  font-size: 0.9rem !important; 
}
