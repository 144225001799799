.ItemClusterCard {
  padding: 0.5rem;
  margin-bottom: 1rem;
  gap: 2rem;
  background-color: #F8F8F8;
  border: 1px solid #DDD;
}

.ItemClusterCard:nth-child(even) {
  background-color: #FDFDFD;
}

.unshownClusteredIdeas {
  font-size: 0.9rem;
  padding: 0.5rem;
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid rgba(0,0,0,0.1);
}
