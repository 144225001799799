.pageHeader {
  border-bottom: 1px solid rgba(0,0,0,0.1);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  display: flex;
  gap: 1rem;
}

.titleContainer, .searchContainer {
  flex: 1;
}

.titleContainer h1 {
  margin-bottom: 1rem;
}

.searchInputContainer {
  position: relative;
  margin-bottom: 0.5rem;
}

/* Move the clear search button left to make room for the advanced search button */
.searchInputContainer.hasAdvancedSearchBtn :global(.bx--search-close) {
  margin-right: 2.5rem;
}

.advancedSearchBtn {
  position: absolute !important;
  right: 5px;
  top: 50%;
  margin-top: -15px;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  min-height: auto;
  padding: 0 !important;
  background-color: transparent;
  color: #333;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
}

.advancedSearchBtn:hover, .advancedSearchBtn:focus {
  background-color: #e5e5e5;
  color: #161616;
}

.advancedSearchBtn.active {
  background-color: #888;
  color: #e5e5e5;
}

.advancedSearchBtn.active:hover, .advancedSearchBtn.active:focus {
  background-color: #999;
}

.advancedSearchBy {
  display: flex;
  gap: 0.5rem;
}

.intro p {
  margin-bottom: 0.3rem;
}
