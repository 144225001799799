.loading {
  margin: 100px auto;
}

.spinner {
  margin: 0 auto 20px;
}

.text {
  text-align: center;
  color: #666;
  font-size: 0.9rem;
}
