.ideaCard {
  background-color: #FAFAFA;
  border: 1px solid #EEE;
}

@keyframes box-shadow-fade-out {
  to { box-shadow: 0 0 10px rgba(255, 255, 0, 0); }
}

.ideaCard.newIdea {
  box-shadow: 0 0 10px rgba(255, 255, 0, 0.5);
  animation-name: box-shadow-fade-out;
  animation-delay: 5s;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.ideaCard .content {
  padding: 1rem;
}

.ideaCard header {
  display: flex;
  align-items: flex-start;
  column-gap: 1rem;
  row-gap: 0.5rem;
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 800px) {
  .ideaCard header {
    flex-wrap: wrap;
  }

  .ideaCard header h4 {
    width: 100%;
  }
}

.ideaCard header h4 {
  flex-grow: 1;
  font-weight: 600;
  color: #333;
}

.ideaCard header h4 a {
  text-decoration: none;
  color: #333;
}

.ideaCard header h4 a:hover {
  color: #000;
}

.ideaCard header h4 .visibilityIcon {
  margin-right: 0.2rem;
  vertical-align: -2px;
}

.ideaCard header h4 .launchIcon {
  margin-left: 0.2rem;
  vertical-align: -2px;
}

.ideaCard header .workflow {
  flex-shrink: 0;
  border: 1px solid #EEE;
  background-color: #EEE;
  border-radius: 100px;
  padding: 2px 10px;
  font-size: 0.9rem;
}

.ideaCard header .workflow button {
  border-bottom: none;
}

.description {
  padding-bottom: 0.7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  color: #555;
  font-size: 0.9em;
}

.meta > div {
  display: flex;
  align-items: center;
  gap: 0.2rem;
}

.groupBtnsContainer {
  display: flex;
  gap: 5px;
}

.addToGroupBtn, .removeFromGroupBtn {
  width: 24px;
  height: 24px;
  min-height: 24px;
  padding: 0 !important;
  justify-content: center;
  background-color: transparent;
  color: #161616;
}

.addToGroupBtn:hover, .removeFromGroupBtn:hover {
  background-color: #e5e5e5;
  color: #161616;
}

.addToGroupMenu { width: 15rem; }
.addToGroupMenu :global(.bx--overflow-menu-options__btn) {
  max-width: none;
}

.addToGroupMenu .groupOption {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.addToGroupMenu .groupOption svg {
  margin-right: 5px;
}

.voteComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  padding: 0.2rem 0.4rem;
}

.iconButtonSpinner {
  width: 24px;
  height: 24px;
  min-height: 0;
  justify-content: center;
}

.iconButtonSpinner :global(.bx--inline-loading__animation) {
  margin-right: 0;
}

.tagsIcon {
  flex-shrink: 0;
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.2rem;
}

.addTagBtn {
  background-color: transparent;
  color: #161616;
  border-radius: 100px;
  width: 20px;
  height: 20px;
  min-height: auto;
  padding: 0 !important;
  justify-content: center;
}

.addTagBtn:hover {
  background-color: #e5e5e5;
  color: #161616;
}

.addTagComboBox :global(.bx--list-box__menu){
  min-width: 300px;
}

.addTagComboBox {
  max-height: 20px;
}

.ideaCard .privilegedContent {
  padding: 0.5rem 1rem;
  background-color: #EEE;
  color: #555;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.ideaCard .privilegedItemsContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
}

.privilegedItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.privilegedItem strong {
  font-weight: 500;
}

.privilegedItem:not(:last-child)::after {
  content: '·';
}

.privilegedItem :global(.bx--btn--sm) {
  min-height: 20px;
  padding: 0 0.4rem;
}

.reference {
  opacity: 0.7;
  font-weight: lighter;
  font-size: 0.8em;
  white-space: nowrap;
}
