.header {
  margin-bottom: 2rem;
}

.container {
  display: flex;
  gap: 1rem;
}

.sidebar {
  width: 300px;
  flex-shrink: 0;
}

.sidebarList {
  position: sticky;
  top: 60px;
}

.sidebar a {
  width: 100%;
  justify-content: left;
  gap: 0.5rem;
}

.sidebarList > li {
  margin-bottom: 1rem;
}

.sidebar .sidebarList ul li a {
  padding-left: 2rem;
  border: none;
}

.sidebarList .toplevel a {
  font-weight: bold;
}

.sidebarList .topLevelTitle {
  border-top: 2px solid #c6c6c6;
}

.sidebarList .topLevelIndented a {
  padding-left: 1.7rem;
}

.sidebarList .topLevelIndented a:after {
  position: absolute;
  content: '-';
  left: 1rem;
  font-weight: normal;
}

.bigTopTenIcon {
  width: 12px;
}

.main {
  flex-grow: 1;
  min-width: 0;
}


.sidebar a:global(.active) {
  position: relative;
  background-color: #f3f3f3;
  font-weight: bold;
  box-shadow: none;
  border: none;
}

.sidebar a:global(.active)::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #ddd;
}

.sidebar .groupButton {
  padding-left: 2rem;
}

.sidebar .groupButtonText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mainTitle {
  font-size: 2.5em;
}

.issueTrackerSlogan {
  font-style: italic;
}

.internalDisclaimer {
  font-size: 1em;
  float: right;
}

.internalDisclaimer svg {
  vertical-align: -2px;
  margin-right: 0.2rem;
}
