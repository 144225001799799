.commentBtn {
    display: flex;
}
.commentBtn span, .commentBtn a {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    color: inherit;
    text-decoration: none;
  }
