.PageTabs {
  margin-bottom: 1rem;
}

.PageTabs :global(.bx--tabs__nav-link) {
  width: auto;
}

.PageTabs :global(.bx--tabs__nav-link):focus {
  width: auto;
}