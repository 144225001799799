.membersTable th {
  font-weight: bold;
  text-align: left;
  padding-bottom: 0.5rem;
}

.membersTable td,
.membersTable th {
  padding-right: 1rem;
  vertical-align: middle;
}

.membersTable td:first-child {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.membersTable .colAdmin {
  text-align: center;
}

.membersTable .colAdmin :global(.bx--form-item) {
  align-items: center;
}