.ideaCommentContainer {
    border-bottom: 2px solid #eee;
}
.ideaCommentContainer p {
  margin: 0rem;
}
.ideaComment, .ideaChildComment {
    padding: 0.5rem;
    padding-top: 1rem;
    margin: 0.5rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
}
.ideaChildComment {
    margin-left: 4rem;
    border-left: 2px solid #eee;
}
.commentDetails {
  display: grid;
  grid-template-columns: 0.05fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0rem 0.5rem;
  grid-template-areas:
    'avatar name reply'
    'avatar date reply';
}
.userAvatar, .ibmUserAvatar {
  grid-area: avatar;
  /* padding-right: 0.5rem; */
}
.ibmUserAvatar {
  fill: #0f62fe !important;
}
.userAvatar {
  fill: #000 !important;
}
.userName {
  grid-area: name;
}
.replyButton {
  grid-area: reply;
}
.commentDate {
  grid-area: date;
  color: #444;
  font-size: 0.8rem;
  --gap: 0.75rem;
  display: flex;
  gap: var(--gap);
  padding-top: 0.2rem;

}
.commentDate > :not(:last-child)::after {
  margin-left: var(--gap);
  content: '·';
  opacity: 0.5;
}
.comment {
  padding-top: 0.5rem;
  padding-left: 2rem;
  overflow-wrap: break-word
}

.comment pre {
  white-space: normal;
  line-height: normal;
  width: 100%;
  margin-top: 1rem;
  background: #c7c7c74d;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  padding: 0.5em;
}

.comment ol {
  list-style-type: decimal;
}
.comment ul {
  list-style-type: disc;
}

.comment ul, .comment ol {
  margin-bottom: 1rem;
}

.comment li {
  margin-bottom: 0.25rem;
}

.comment table, .comment td, .comment th{
  border: 1px solid;
  padding: 0.5rem;
}

@keyframes box-shadow-fade-out {
  to { box-shadow: 0 0 10px rgba(255, 255, 0, 0); }
}

.ideaCommentContainer.newComment {
  box-shadow: 0 0 10px rgba(64, 255, 0, 0.5);
  animation-name: box-shadow-fade-out;
  animation-delay: 5s;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
