.itemLink {
    font-size: inherit;
    text-decoration: none;
    text-align: center;
    margin-top: 2rem;
  }
  
  .itemLink svg {
    vertical-align: -2px;
  }
  .commentsRow {
    display: grid;
    grid-template-columns: 2fr 0.5fr 0.5fr; 
    align-items: center; 
    margin-bottom: 1rem;
  }
  .orderDropdown {
    width: 150px
  }
  .perPageDropdown {
    width: 100px
  }
  .newCommentBox {
    margin-top: 1rem;
  }
