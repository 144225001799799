.section {
  margin-bottom: 50px;
}

.section h2 {
  margin-bottom: 1rem;
}

.section h3 {
  margin-bottom: 1rem;
}
