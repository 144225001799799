.IssueTrackerParent h4 {
  margin-bottom: 1rem;
}

/* Summary tables (common to both tabs) */

.summaryTable {
  margin-bottom: 2rem;
}

.summaryTable th, .summaryTable td {
  text-align: center;
}

.summaryTable th:first-child, .summaryTable td:first-child {
  text-align: left;
  white-space: nowrap;
}

.summaryRow td {
  background-color: white;
  padding: 0;

  /* Hack to make links full height */
  height: 1px;
  min-height: 1px;
}

.summaryRow td a {
  display: block;
  color: inherit;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.summaryRow td a:hover {
  background-color: rgba(0,0,0,0.05);
}

.summaryTable td:first-child {
  font-weight: 500;
}

.summaryTable .zero {
  color: rgba(0,0,0,0.7);
}

.summaryTable .totalRow td {
  background-color: #F5F5F5;
  font-weight: 500;
  padding: 0.5rem 1rem;
}

.summaryTable tr:nth-last-child(2) td {
  /* Darker line above total row */
  border-bottom: rgba(0,0,0,0.2) 1px solid;
}

/* Tab specific table styles */

.summaryOutlookTable tr :nth-last-child(2) {
  border-left: rgba(0,0,0,0.3) 1px solid;
}

.summaryInclusionTable td:first-child {
  padding: 0.5rem 1rem;
}

.summaryInclusionTable th:last-child, .summaryInclusionTable td:last-child {
  border-left: rgba(0,0,0,0.3) 1px solid;
}

/* Outlook definitions */

.outlookDefinitions {
  font-size: 0.875rem;
}

.outlookDefinitions th {
  font-weight: 500;
  text-align: right;
  padding-bottom: 0.5rem;
  padding-right: 1rem;
  white-space: nowrap;
}

.outlookDefinitions td {
  text-align: left;
  padding-bottom: 0.5rem;
  line-height: 1.3;
}
