.productDropdown :global(.bx--list-box__menu-item),
.productDropdown :global(.bx--list-box__menu-item__option) {
  height: auto;
}

.productDropdown :global(.bx--list-box__menu-icon) {
  display: none;
}

.aliasLine {
  font-size: 0.8em;
  margin-top: 0.2rem;
}
