.shell {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.content {
  margin-top: 1rem;
  margin-bottom: 6rem;
}

@media screen and (max-width: 65.98rem) {
  :global(.bx--grid) {
    padding: 0 1rem;
  }
}

:global(.bx--header__nav-caret-left-container),
:global(.bx--header__nav-caret-right-container),
:global(.bx--masthead__search) {
  display: none;
}

:global(.bx--dotcom-shell) {
  flex-grow: 1;
}

:global(.bx--legal-nav__list) {
  border-top: none;
}

:global(.bx--list-box__field--wrapper) {
  display: flex;
  height: inherit;
  align-items: center;
}