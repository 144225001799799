.newGroupForm h4 {
  margin-bottom: 1rem;
}

.groupNameInput {
  max-width: 500px;
  margin-bottom: 1rem;
}

.createBtn {
  margin-bottom: 1rem;
}
