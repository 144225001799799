.addGroupMemberForm h4 {
  margin-bottom: 1rem;
}

.formRow {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 1rem;
}

.formRow :global(.bx--form-item) {
  flex-grow: 0;
}

.emailInput {
  width: 500px;
  margin-bottom: 1rem;
}

.roleDropdown {
  width: 150px;
}

.createBtn {
  margin-bottom: 1rem;
}
