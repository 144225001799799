.tag {
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  color: #161616;
}

.name {
  padding: 0 0.6rem;
  background-color: transparent;
  border: none;
  color: #161616;
  font-size: 0.9rem;
  line-height: 1.25rem;
}

button.name:not(:disabled) {
  cursor: pointer;
}

.removeBtn {
  border: none;
  background-color: rgba(0,0,0,0.1) !important;
  display: inline-flex;
  width: 20px;
  height: 20px;
  padding: 4px;
  margin-left: -0.3rem !important;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.removeBtn:hover,
.removeBtn:focus {
  background-color: rgba(0,0,0,0.2) !important;
}

.removeBtn[disabled] svg {
  fill: rgba(0,0,0,0.1) !important;
}
