.addGroupTag h4 {
  margin-bottom: 1rem;
}

.formRow {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.formRow :global(.bx--form-item) {
  flex-grow: 0;
}

.nameInput {
  width: 250px;
}
