.multiAddToGroupDialog :global(.bx--modal-container) h3 {
  margin-bottom: 1rem;
}

.multiAddToGroupDialog :global(.bx--modal-container) button {
  max-width: none;
  padding-bottom: 1rem;
}

.submitContainer {
  flex: 0 1 50%;
}

.submitLoading {
  height: 64px;
  margin-left: 1rem;
}
