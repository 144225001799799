.header {
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem
}

.header :global(.bx--form-item) {
  flex-grow: 0;
}

.leaveGroupBtn {
  width: 100%;
}

.groupNameContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  min-width: 0;
}

.groupName {
  overflow: hidden;
  text-overflow: ellipsis;
}

.groupNameInput {
  max-width: 500px;
  font-size: 0.9rem;
}

.metadata {
  margin-bottom: 1rem;
}

.hiddenideas {
  margin-bottom: 1rem;
  opacity: 0.7;
  line-height: 1.3;
}

.hiddenIdeasRefs {
  margin-left: 0.5rem;
  margin-right: 0.1rem;
}

.hiddenIdeasButton {
  background-color: white;
  color: black;
  font-size: 0.9rem;
  border: none;
  cursor: pointer;
}

.groupOptions {
  margin-bottom: 2rem;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.tabs {
  margin-bottom: -1px;
}

.tabs a {
  padding: 0.7rem 1.3rem;
  /* border-bottom: 2px solid #e0e0e0; */
  text-decoration: none;
  color: inherit;
  display: inline-block;
}

.tabs a svg {
  margin-right: 2px;
  vertical-align: -2px;
}

.tabs a:global(.active) {
  font-weight: bold;
  border-bottom: 2px solid #0f62fe;
}

.memberRequestCount {
  margin-left: 0.5rem;
  background-color: #eeeeee;
  border-radius: 100vw;
  padding: 0 0.3rem;
  font-size: 0.9rem;
}

.errorMessage {
  margin: 0;
  margin-bottom: 1rem;
  max-width: none;
}

.errorMessage :global(.bx--inline-notification__text-wrapper) {
  flex-direction: column;
}
