.multiAddManagedTagDialog :global(.bx--modal-container) h3 {
    margin-bottom: 1rem;

}

.multiAddManagedTagDialog :global(.bx--modal-footer) button {
    max-width: none;
    padding-bottom: 1rem;
}

.multiAddManagedTagDialog :global(.bx--radio-button-wrapper .bx--radio-button__label) {
    align-items: center;
}

.radioButtonGroup {
    margin-bottom: 0.5rem;
}

.formRow {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.submitContainer {
    flex: 0 1 50%;
}

.submitLoading {
    height: 64px;
    margin-left: 1rem;
}

.multiAddManagedTagDialog :global(.bx--modal-content) {
    overflow: visible;
}

.multiAddManagedTagDialog :global(.bx--modal-container) {
    overflow: visible;
}
