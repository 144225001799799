.settingsOptions {
    margin-bottom: 2rem;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  
  .tabs {
    margin-bottom: -1px;
  }
  
  .tabs a {
    padding: 0.7rem 1.3rem;
    /* border-bottom: 2px solid #e0e0e0; */
    text-decoration: none;
    color: inherit;
    display: inline-block;
  }
  
  .tabs a svg {
    margin-right: 2px;
    vertical-align: -2px;
  }
  
  .tabs a:global(.active) {
    font-weight: bold;
    border-bottom: 2px solid #0f62fe;
  }
