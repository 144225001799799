.headerBanner, .warningMessage {
    width:100%;
    max-width:100%;
  }

  .bannerMessage {
    line-height: 1.28572;
    letter-spacing: 0.16px;
    word-break: break-word;
  }

  .bannerMessage p {
    font-size: 0.875rem;
    font-weight: 400;
  }