.companyInputField {
  margin-block: 1rem;
}

.companyTable .colCompanyType {
  width: 140px;
}

.companyTable .colCompanyID {
  width: 140px;
}

.companyTable .colRegister {
  width: 220px;
}