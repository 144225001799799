.sidebar {
  width: 250px;
  flex-shrink: 0;
}

@media screen and (max-width: 1000px) {
  .sidebar {
    width: 200px;
  }
}


.sidebarSection {
  margin-bottom: 1rem;
}

.sidebar a {
  display: flex;
}

.interactionSection a:global(.active) {
  position: relative;
  background-color: #f3f3f3;
  font-weight: bold;
  box-shadow: none;
  border: none;
}

.interactionSection a:global(.active)::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #ddd;
}

.interactionSection .groupButton {
  padding-left: 2rem;
}

.interactionSection .groupButtonText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.interactionSection .categoryButton {
  padding-left: 3rem;
}