.listingFilters {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

@media screen and (max-width: 1300px) {
  .filterQueryContainer {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

.listingFilters > div {
  flex-shrink: 0;
}

.filterQueryContainer {
  margin-right: 0.5rem;
  flex-grow: 1;
}

.listingFilters :global(.bx--list-box) {
  border-bottom: none;
}

.listingFilters :global(.bx--tag--filter) {
  display: none;
}

.listingFilters :global(.bx--list-box--expanded .bx--list-box__menu) {
  min-width: 300px;
  max-height: 30rem;
}

.listingFilters :global(.bx--list-box__field) {
  padding-right: 2rem;
}

.listingFilters :global(.bx--list-box__menu-icon) {
  right: 0.5rem;
}

.listingFilters :global(.bx--list-box__wrapper--inline) {
  display: flex;
}

.categoryFilter :global(.bx--list-box__field)::before {
  content: 'Category';
}

.dropdownItem {
  display: flex;
  justify-content: space-between;
}

.dropdownItem .dropdownName {
  text-overflow: ellipsis;
  flex-grow: 0;
  overflow: hidden;
}

.productItem {
  font-weight: 500;
}

.categoryItem {
  margin-left: 1rem;
}

.categoryItem .dropdownName::before {
  content: "\00BB";
  margin-right: 0.5rem;
}
