.followedProducts p {
  margin-bottom: 1rem;
}

.info {
  background-color: #FAFAFA;
  border: 1px solid #EEE;
  padding: 1rem;
}

.section {
  margin-bottom: 3rem;
}

.section h4 {
  margin-bottom: 1rem;
}

.followedProductsTable td {
  vertical-align: middle;
  padding-bottom: 0.5rem;
  min-width: 20rem;
  padding-right: 1rem;
}

.categoryRow td:first-child {
  padding-left: 1rem;
}

.categoryRow td:first-child::before {
  content: "\00BB";
  display: inline-block;
  margin-right: 0.5rem;
}